import {Project, create, TYPES, LOCATIONS} from "../models";
import {copyName, cloneAssign} from "coremarine-frontend-lib/utils/functions";
import frankAPI from "../FrankAPI";
import generateId from "../../utils/generateId";

const getKey = type => type.split("/")[0] + "s";

const byId = (state, id) => state.projectsPending[id] ||
                            state.projectsSaved[id];


function addObject(project, type, object) {
    const key = getKey(type);
    Object.assign(project, {
        [key]: {
            ...project[key],
            [object.id]: object
        }
    });
}

function removeObjects(project, type, ids) {
    const dict = getKey(type);
    ids.forEach(objId => {
        delete project[dict][objId];
    });
    project[dict] = {
        ...project[dict]
    };
}

function updateProject(state, project) {
    state.projectsPending = {
        ...state.projectsPending,
        [project.id]: Object.assign({}, project)
    };
}

export default {
    namespaced: true,
    state: {
        projectsSaved: {},
        projectsPending: {},
        projectMap: {
            center: {lat: 0, lon: 0}
        },
        progress: {},
        results: {},
        lastFarmEdited: null
    },
    getters: {
        byId: state => id => byId(state, id),
        mapCenter: state => state.projectMap.center,
        progress: state => id => state.progress[id] ?? false,
        results: state => id => state.results[id]
    },
    mutations: {
        setList(state, projects) {
            state.projectsSaved = Object.fromEntries(
                projects.map(p => [p.id, new Project().init(p)])
            );
        },
        setCenter(state, {lat, lon}) {
            state.projectMap.center = {lat, lon};
        },
        setProgress(state, {id, progress}) {
            state.progress = {
                ...state.progress,
                [id]: progress
            };
        },
        finishProgress(state, {id}) {
            delete state.progress[id];
            state.progress = {
                ...state.progress
            };
        },
        create(state, data = {}) {
            const project = new Project().init(data);
            updateProject(state, project);
        },
        selectFarm(state, farm) {
            state.lastFarmEdited = farm;
        },
        createObject(state, {id, type, data = {}}) {
            const project = byId(state, id);
            const object = create(type, data);
            addObject(project, type, object);
            updateProject(state, project);
            if(type.indexOf(TYPES.FARM) !== -1) {
                state.lastFarmEdited = object.id;
            }
        },
        addObject(state, {id, object, type}) {
            const project = byId(state, id);
            addObject(project, type, object);
            updateProject(state, project);
        },
        modify(state, {id, updates}) {
            const project = byId(state, id);
            Object.assign(project, updates);
            updateProject(state, project);
        },
        modifyFarm(state, {id, farm, updates}) {
            const project = byId(state, id);
            Object.assign(project, {
                farms: {
                    ...project.farms,
                    [farm]: {
                        ...project.farms[farm],
                        ...updates
                    }
                }
            });
            state.lastFarmEdited = farm;
            updateProject(state, project);
        },
        removeObject(state, {id, type, ids}) {
            const project = byId(state, id);

            removeObjects(project, type, ids);
            if(ids.indexOf(state.lastFarmEdited) !== -1) {
                state.lastFarmEdited = Object.keys(project.farms).pop();
            }
            for(let farm in project.farms) {
                for(let dep in project.farms[farm].depends) {
                    if(ids.indexOf(project.farms[farm][dep]) != -1) {
                        project.farms[farm][dep] = "";
                    }
                }
                for(let dep in project.farms[farm].depends_arr) {
                    if(project.farms[farm][dep]) {
                        const i = project.farms[farm][dep].findIndex(
                            id => ids.indexOf(id) != -1
                        );
                        if(i !== -1) {
                            project.farms[farm][dep] = [
                                project.farms[farm][dep].slice(0, i),
                                project.farms[farm][dep].slice(i+1)
                            ];
                        }
                    }
                }
            }
            updateProject(state, project);
        },
        add(state, project) {
            state.projectsSaved = {
                ...state.projectsSaved,
                [project.id]: new Project().init(project)
            };
        },
        save(state, {id}) {
            if(state.projectsPending[id]) {
                state.projectsSaved = {
                    ...state.projectsSaved,
                    [id]: state.projectsPending[id]
                };
                delete state.projectsPending[id];
                state.projectsPending = {
                    ...state.projectsPending
                };
            }
        },
        delete(state, {id, fromPending}) {
            if(state.projectsPending[id]) {
                delete state.projectsPending[id];
                state.projectsPending = {
                    ...state.projectsPending
                };
            }
            if(!fromPending && state.projectsSaved[id]) {
                delete state.projectsSaved[id];
                state.projectsSaved = {
                    ...state.projectsSaved
                };
            }
        },
        results(state, {id, results}) {
            state.results = {
                ...state.results,
                [id]: results
            };
        }
    },
    actions: {
        loadAll({commit}) {
            return frankAPI.getObjects("project")
                .then(({data, count}) => {
                    commit("setList", data);
                });
        },
        load({commit}, {id}) {
            return frankAPI.getObject("project", id)
                .then((project) => {
                    commit("delete", {id});
                    commit("add", project);
                });
        },
        create({commit}, data = {}) {
            const project = new Project(data);
            project.init(data);
            return frankAPI.createObject("project", project)
                .then(project => {
                    commit("add", project);
                    commit("modify", { // Move it to pending.
                        id: project.id,
                        updates: {}
                    });
                    return project;
                });
        },
        import({dispatch}, project) {
            return dispatch("create", project);
        },
        save({commit, getters}, {id}) {
            const project = getters.byId(id);
            return frankAPI.updateObject("project", project)
                .then(project => {
                    commit("save", project);
                    return project;
                });
        },
        copy({dispatch, getters}, {id}) {
            const project = getters.byId(id);

            return dispatch("create", {
                ...cloneAssign({}, project),
                name: copyName(project.name)
            });
        },
        delete({commit}, payload) {
            return frankAPI.deleteObject("project", payload.id)
                .then(ok => {
                    if(ok) {
                        commit("delete", payload);
                        return ok;
                    }
                });
        },
        process({state, commit, dispatch}, {id}) {
            const project = byId(state, id);
            if(state.progress[id] !== undefined) {
                return;
            }
            commit("setProgress", {id, progress: 0});

            return dispatch("save", project)
                .then(() => {
                    return frankAPI.startSimulation(
                        project,
                        progress => commit("setProgress", {id, progress}),
                        error => {
                            commit("setProgress", {id, progress: -1});
                            return null;
                        }
                    );
                })
                .then((results) => {
                    commit("finishProgress", {id});
                    commit("results", {id, results: results});
                    commit("modify", {id, updates: {results_id: results.id}});
                    commit("save", project);
                    return results;
                });
        },
        loadResults({state, commit, getters, dispatch}, {id, _rec}) {
            const project = byId(state, id);
            if(!project) {
                if(!Object.keys(state.projectsSaved).length && !_rec) {
                    return dispatch("loadAll")
                        .then(() => dispatch("loadResults", {id, _rec: true}));
                }
                throw Error("No project found");
            }
            return new Promise((resolve, reject) => {
                if(project.results_id) {
                    let results = getters.results(project.results_id);
                    if(results) {
                        resolve(results);
                    } else {
                        frankAPI.loadResults(project.id)
                            .then(results => {
                                commit("results", {id, results});
                                resolve(results);
                            })
                            .catch(err => reject(err));
                    }
                }
                else reject();
            });
        },
        loadResultsCase({state, commit, getters, dispatch}, {id, case_id, _rec}) {
            const project = byId(state, id);
            return frankAPI.loadCase(project.id, case_id);
        }
    }
};