
import ProviderBase from "coremarine-frontend-lib/apitools/ProviderBase";


class FrankAPI extends ProviderBase {
    loadSchemas() {
        return this.fetchJson("/openapi.json");
    }
    getChangelog() {
        return this.reqTextWithAuth("changelog");
    }
    getAppInfo() {
        return this.reqWithAuth("info");
    }
    parseErrorResponce(res) {
        return res.json();
    }
    startSimulation(data, onProgress, onError) {
        return this.reqWithAuth(`simulation/${data.id}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                onProgress(0);
                return this.watchProgress(data.id, res.id, onProgress, onError);
            })
            .catch(err => onError(err));
    }
    _watchProgress(projId, id, onProgress, onError, prev, resolve, reject) {
        setTimeout(() => {
            this.reqWithAuth(`simulation/progress/${id}`)
                .then(res => {
                    if(res.finished) {
                        this.loadResults(projId)
                            .then(res => resolve(res)
                            );
                    } else {
                        if(prev < res.progress)
                            onProgress(res.progress);
                        this._watchProgress(
                            projId,
                            id, onProgress, onError, res.progress,
                            resolve, reject
                        );
                    }
                })
                .catch(err => {
                    onError(err);
                    reject();
                });
        }, 500);
    }
    watchProgress(projId, id, onProgress, onError) {
        return new Promise((resolve, reject) => {
            this._watchProgress(
                projId, id, onProgress,
                onError, 0, resolve, reject);
        });
    }
    loasdDatasources() {
        return this.reqWithAuth(`my/datasources`);
    }
    loadResults(id) {
        return this.reqWithAuth(`projects/${id}/results`);
    }
    loadCase(id, caseId) {
        return this.reqWithAuth(`projects/${id}/results/${caseId}`);
    }
    getObjects(type) {
        return this.reqWithAuth(`${type}s`);
    }
    getObject(type, id) {
        return this.reqWithAuth(`${type}s/${id}`);
    }
    createObject(type, data) {
        return this.postJsonWithAuth(`${type}s`, data);
    }
    updateObject(type, data) {
        return this.putJsonWithAuth(`${type}s/${data.id}`, data);
    }
    deleteObject(type, id) {
        return this.deleteJsonWithAuth(`${type}s/${id}`);
    }
}

export default (new FrankAPI(""));