<template>
    <div
        id="app"
        class="h-100"
    >
        <router-view>
            <template #navbar-content />
        </router-view>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    components: {},
    computed: {
        ...mapState("user", ["user"]),
        loggedIn() {
            return this.user.loggedIn;
        }
    },
    watch: {
        loggedIn(cur, prev) {
            if(!cur && prev) {
                this.$store.dispatch("frank/exit");
                this.toLoginPage();
            } else if(cur && !prev) {
                this.checkAuth();
            }

        },
        $route(r, prev) {
            if(!this.loggedIn) {
                this.toLoginPage();
            }
        }
    },
    created() {
        this.checkAuth();
    },
    methods: {
        checkAuth() {
            this.loading = true;
            this.$store.dispatch("user/auth")
                .then(() => {
                    if(!this.user.loggedIn) {
                        this.toLoginPage();
                    } else {
                        if((this.user.permissions.frank||"none") === "none") {
                            this.$store.dispatch("user/logout");
                            this.toLoginPage();
                        } else if(this.$route.name == "login") {
                            this.$router.push({name: "frank_new_project"});
                        }
                    }
                    this.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.toLoginPage();
                    this.loading = false;
                });
        },
        toLoginPage() {
            if(this.$route.name !== "login") {
                this.$router.push({name: "login"});
            }
        }
    }
};
</script>


<style lang="scss">
@import "coremarine-frontend-lib/styles";

.not-found {
    max-height: 500px;
}

</style>
