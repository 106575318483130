
import frankAPI from "./FrankAPI";
import modules from "./modules";


const initDataState = () => ({
    databases: [{
        k: "atlantic",
        label: "Atlantic Database"
    }],
});

export default {
    namespaced: true,
    state: {
    },
    mutations: {
    },
    actions: {
        initialize({dispatch, rootState}, {config}) {
            frankAPI.setApiUrl(config.app.apiUrl);
            frankAPI.setAuthManager(rootState.authManager);
            dispatch("config/populate", config);
            dispatch("config/getAppInfo");
            dispatch("data/initialize");
        },
        exit({dispatch}) {
            dispatch("data/clear");
        },
        loadUserData({dispatch}) {
            dispatch("project/loadAll");
            dispatch("config/getChangelog");
            dispatch("data/loadDatasources");
        },
        uploadProject({dispatch}, project) {
            const objects = ["asset", "controller", "vessel"];
            dispatch("project/import", project);
        },
    },
    modules: {
        ...modules,
        data: {
            namespaced: true,
            state: initDataState(),
            mutations: {

            },
            actions: {
                initialize() {},
                loadDatasources({state}) {
                    frankAPI.loasdDatasources()
                        .then(({items}) => {
                            state.databases = [
                                ...state.databases,
                                ...items.map(({name, id}) => ({
                                    k: id,
                                    label: name
                                }))
                            ];
                        });

                },
                clear({state}) {
                    Object.assign(state, initDataState());
                }
            }
        },

    }
};