import BaseObject from "./BaseObject";


class Location extends BaseObject {
    lat = 45;
    lon = 0;
    extended = false;

    constructor(type, name) {
        super();
        this.type = type;
        this.name = name;
    }
}

export const LOCATIONS = {
    LEASE_AREA: "LeaseArea",
    MOBILIZATION_PORT: "MobilizationPort",
    TRANSFORMER: "Transformer",
    WAYPOINT: "Waypoint",
    WEATHER: "Weather",
    ENDPOINT: "Endpoint"
};

export class LeaseArea extends Location {
    extended = true;
    polygon = [];

    constructor() {
        super(LOCATIONS.LEASE_AREA, "Lease Area");
    }
    init(data = {}) {
        super.init(data);
        if (!data.lon && this.polygon.length) {
            this.lat = this.polygon.reduce(
                (avg, {lat}) => avg + lat / this.polygon.length,
                0
            );
            this.lon = this.polygon.reduce(
                (avg, {lon}) => avg + lon / this.polygon.length,
                0
            );
        }
        return this;
    }
}

export function createLocation(type, data) {
    const create = {
        [LOCATIONS.LEASE_AREA]: () => new LeaseArea(),
        [LOCATIONS.MOBILIZATION_PORT]: () =>
            new Location(LOCATIONS.MOBILIZATION_PORT, "Mobilization Port"),
        [LOCATIONS.WAYPOINT]: () =>
            new Location(LOCATIONS.WAYPOINT, "Waypoint"),
        [LOCATIONS.ENDPOINT]: () =>
            new Location(LOCATIONS.ENDPOINT, "Route end"),
        [LOCATIONS.WEATHER]: () =>
            new Location(LOCATIONS.WEATHER, "Weather"),
        [LOCATIONS.TRANSFORMER]:  () =>
            new Location(LOCATIONS.TRANSFORMER, "Transformer"),
    }[type];
    if(create) {
        const l = create();
        l.init(data);
        return l;
    } else throw RangeError("No location type: " + type);
}