import Vue from 'vue';
import App from './App.vue';
import routes from './routes';
import "./bootstrap";
import Vuex from 'vuex';
import VueRouter from "vue-router";
import frankStore from "./store/frank";
import rootStore from "coremarine-frontend-lib/store";
import AuthManager from "./store/AuthManager";

Vue.config.productionTip = false;

Vue.use(VueRouter);
const router = new VueRouter({
    routes
});
router.beforeEach((to, from, next) => {
    let title = ["FRANK"];
    if(to.meta?.title) {
        if(Array.isArray(to.meta.title)) {
            title = [...to.meta.title, ...title];
        } else if(typeof to.meta.title == "function") {
            title = [...to.meta.title(to), ...title];
        }
    }
    document.title = title.join(" | ");
    next();
});

Vue.use(Vuex);

const store = new Vuex.Store(rootStore);
store.registerModule("frank", frankStore);

store.dispatch("initialize", {
    authManager: new AuthManager("api/"),
    config: {
        app: {
            display: {
                userMenu: true
            }
        },
        feedback: {
            url: "/api/feedback",
            labels: ["frontendFeedback"],
            enable: true,
        }
    }
})
    .then(() => {
        store.dispatch("frank/initialize", {
            config: {
                app: {
                    apiUrl: "/api/",
                    feedbackLabels:  ["frankFrontendFeedback"],
                    feedbackJiraKey: "FRNK"
                },
                map: {
                    initCenter: [-10, 45]
                }
            }
        });
        new Vue({
            router,
            store,
            render: h => h(App)
        }).$mount('#app');

    });


