import BaseObject from "./BaseObject";
import {fromMeters} from "coremarine-frontend-lib/utils/coordUtils";

const year = (new Date().getFullYear());

export default class Project extends BaseObject {
    name = "Sample project";
    time = {
        "start": `${year+1}-01-01`,
        "end": `${year+21}-12-31`,
        "step": 60
    };
    weather_db = "atlantic";
    farms = {};
    locations = {};
    assets = {};
    devices = {};
    failures = {};
    simulation_params = {
        num_cases: 1000,
        random_seed: null,
        store_cases: false
    };
    meta = {
        created: "",
        updated: ""
    };
    init(data = {}) {
        super.init(data);
        for(let farmId in this.farms) {
            const farm = this.farms[farmId];
            if(!farm.layout_coords ||
                farm.layout_coords.length != farm.layout.length) {
                this._updateLayout(farm);
            }
        }
        return this;
    }
    _updateLayout(farm) {
        const area = this.locations[farm.lease_area];
        farm.layout_coords = farm.layout
            .map(([x, y]) => fromMeters(area.lat, area.lon, x, y))
            .map(([lat, lon]) => ({lat, lon}));
    }
}
