
import { createAsset } from "./Asset";
import { createLocation } from "./Location";
import { createDevice } from "./Device";
import { createFarm } from "./Farm";

export const TYPES = {
    LOCATION: "location",
    DEVICE: "device",
    ASSET: "asset",
    FARM: "farm"
};

export function create(type, data) {
    const [factType, subType] = type.split("/");
    const factory = {
        [TYPES.LOCATION]: createLocation,
        [TYPES.DEVICE]: createDevice,
        [TYPES.ASSET]: createAsset,
        [TYPES.FARM]: createFarm
    }[factType];

    if(factory) {
        return factory(subType, data);
    } else throw RangeError("Unknown type: " + type);
}