import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import Vue from 'vue';
import {
    BadgePlugin,
    ButtonPlugin,
    CardPlugin,
    CollapsePlugin,
    DropdownPlugin,
    FormPlugin,
    FormCheckboxPlugin,
    FormGroupPlugin,
    FormInputPlugin,
    FormRadioPlugin,
    FormSelectPlugin,
    FormTextareaPlugin,
    InputGroupPlugin,
    LayoutPlugin,
    ListGroupPlugin,
    NavPlugin,
    NavbarPlugin,
    PopoverPlugin,
    SpinnerPlugin,
    TablePlugin,
    TabsPlugin
} from 'bootstrap-vue';

Vue.use(BadgePlugin);
Vue.use(ButtonPlugin);
Vue.use(CardPlugin);
Vue.use(CollapsePlugin);
Vue.use(DropdownPlugin);
Vue.use(FormPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(InputGroupPlugin);
Vue.use(LayoutPlugin);
Vue.use(ListGroupPlugin);
Vue.use(NavPlugin);
Vue.use(NavbarPlugin);
Vue.use(PopoverPlugin);
Vue.use(SpinnerPlugin);
Vue.use(TablePlugin);
Vue.use(TabsPlugin);