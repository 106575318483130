import BaseObject from "./BaseObject";


const DEPS = {
    lease_area: "locations",
    transformer: "locations",
    asset: "assets",
};

Object.freeze(DEPS);

export class Farm extends BaseObject {
    id = "";
    lease_area = "";
    weather_point = "";
    asset = "";
    layout = [];
    layout_coords = [];
    operation_controller = "";
    maintenance_fleet = [];
    maintenance_route = [];
    maintenance_schedule = {
        offset_days: 0,
        frequency_year: 1,
        time_hours: 8,
    };
    maintenance_conditions = {
        hs_max: 2,
        vw_max: 10
    };
    params = {
        surface_roughness: .001,
        depth: 10
    };
    cables = [];
    transformer = "";
    reliability = {
        cables:  {rate: .0004, time:  672},
        circ_br: {rate: .03,   time:  120},
        disconn: {rate: .03,   time:  120},
        asset:   {rate: .15,   time:  336},
        transfr: {rate: .02,   time: 2688}
    };
    depends = DEPS;
    depends_arr = {
        maintenance_route: "locations",
        maintenance_fleet: "devices"
    };
}

export function createFarm(_, data) {
    const obj = new Farm();
    obj.init(data);
    return obj;
}