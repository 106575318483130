import BaseObject from "./BaseObject";

export const ASSETS = {
    FWT: "FWT"
};

export class FWT extends BaseObject {
    name = "New turbine";
    type = ASSETS.FWT;
    life = 10;
    rotor_diameter = 220;
    blade_count = 3;
    hub_height = 138;
    loss_factor = 1;
    max_hs = 4;
    power_rate = 12000;
    power_thrust_curve = [];
    cost = 100;
}

export function createAsset(type, data) {
    const cls = {
        [ASSETS.FWT]: FWT,
    }[type];
    if(cls) {
        const l = new cls();
        l.init(data);
        return l;
    } else throw RangeError("No object type: " + type);
}