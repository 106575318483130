export class Repairs {
    id = "";
    asset = false;
    cables = false;
    disconn = false;
    circ_br = false;
    transfr = false;
    planned = false;
}

export const REPAIRS = [
    "asset", "cables", "disconn", "circ_br", "transfr", "planned"
];