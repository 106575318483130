import { create } from "../models";
import {copyName} from "coremarine-frontend-lib/utils/functions";
import frankAPI from "../FrankAPI";

const getList = type => type.split("/")[0] + "s";

const copy = (state, {type, id}) => {
    const list = getList(type);
    if(state[list]) {
        const obj = state[list].find(d => d.id == id);
        const copy = create(type, {});
        copy.init(obj);
        copy.name = copyName(obj.name);
        return copy;
    }
    throw Error("Unknown type", type);
};

export default {
    namespaced: true,
    state: {
        assets: [],
        devices: [],
        controllers: [],
        lease_areas: []
    },
    getters: {
        asset: state => id => state.assets.find(t => t.id == id),
        device:  state => id => state.devices.find(t => t.id == id),
        controller:  state =>
            id => state.controllers.find(t => t.id == id),
    },
    mutations: {
        add(state, {type, data}) {
            const obj = create(type, data);
            const list = getList(type);
            state[list].push(obj);
        },
        addList(state, {type, data}) {
            const list = getList(type);
            state[list] = data.map(item => 
                create(`${type}/${item.type}`, item)
            );
        },
        modify(state, {type, id, data}) {
            const list = getList(type);
            if(state[list]) {
                const objInd = state[list].findIndex(d => d.id == id);
                const obj = state[list][objInd];
                state[list] = [
                    ...state[list].slice(0, objInd),
                    Object.assign({}, obj, data),
                    ...state[list].slice(objInd+1)
                ];
            }
        },
        delete(state, {type, id}) {
            const list = getList(type);
            if(state[list]) {
                const index = state[list].findIndex(d => d.id == id);
                state[list].splice(index, 1);
            }
        }
    },
    actions: {
        getList({commit}, {type}) {
            return frankAPI.getObjects(type)
                .then(({data, count}) => {
                    commit("addList", {type, data});
                });
        },
        create({commit}, {type, data}) {
            const item = create(type, data);
            return frankAPI.createObject(type.split("/")[0], item)
                .then(created => {
                    commit("add", {
                        type, id: created.id, 
                        data: created
                    });
                    return created;
                });
        },
        copy({commit, state}, {type, id}) {
            const data = copy(state, {type, id});

            return frankAPI.createObject(type.split("/")[0], data)
                .then(created => {
                    commit("add", {
                        type, id: created.id, 
                        data: created
                    });
                });           
        },
        save({commit}, {type, data}) {
            return frankAPI.updateObject(type.split("/")[0], data)
                .then(updated => {
                    commit("modify", {
                        type, id: updated.id, 
                        data: updated
                    });
                });    
        },
        delete({commit}, {type, id}) {
            return frankAPI.deleteObject(type.split("/")[0], id)
                .then(ok => {
                    if(ok) {
                        commit("delete", {type, id});
                    }
                });
        },
        import({commit}, {type, data}) {
            return frankAPI.createObject(type.split("/")[0], data)
                .then(data => {
                    commit("add", {type, data});
                });            
        }
    }
};