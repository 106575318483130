import frankAPI from "../FrankAPI";
import LocalStorageManager from "coremarine-frontend-lib/utils/LocalStorageManager";
import { deepAssign } from "coremarine-frontend-lib/utils/functions";

let locStorage = null;

export default {
    namespaced: true,
    state: {
        map: {
            initCenter: [-20, 50],
            initZoom: 4,
            maxZoom: 32,
            tileSources: {
                base: {
                    url:  "https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"
                }
            }
        },
        app: {
            name: "FRANK",
            version: "0.2.0",
            changelog: "",
            apiUrl: "/api/",
            localStorageKey: "frankSettings",
            display: {
                feedback: true,
                userMenu: true,
            }
        },
        localSettings: {}
    },
    getters: {
        settings: state => key => state.localSettings[key]
    },
    actions: {
        populate({state}, config) {
            deepAssign(state, config);
            frankAPI.setApiUrl(state.app.apiUrl);
            locStorage = new LocalStorageManager(state.app.localStorageKey);
        },
        getAppInfo({state}) {
            return frankAPI.getAppInfo()
                .then(({name, version}) => {
                    state.app = {
                        ...state.app,
                        version, name
                    };
                });
        },
        getChangelog({state}) {
            return frankAPI.getChangelog()
                .then(ch => {
                    state.app.changelog = ch;
                });
        },
        loadSettings({state}, {key, defaultValue}) {
            const stored = locStorage.getItem(key);
            if(stored == null) {
                state.localSettings[key] = defaultValue;
                locStorage.setItem(key, defaultValue);
            } else {
                state.localSettings[key] = stored;
            }
        }
    }
};