import BaseObject from "./BaseObject";

class Device extends BaseObject {
    speed = 10;
    cost = 10;
}

export const DEVICES = {
    SOV: "MaintenanceVessel",
    TUG: "Tug",
    CABLE_LAY_VESSEL: "CableLayVessel",
    CTV: "CTV",
    AHTS: "AHTS",
    HELICOPTER: "Helicopter"
};

export class SOV extends Device {
    type = DEVICES.SOV;
    name = "SOV";
}

export class Helicopter extends Device {
    type = DEVICES.HELICOPTER;
    name = "Helicopter";
}

export class CTV extends Device {
    type = DEVICES.CTV;
    name = "CTV";
}

export function createDevice(type, data) {
    const cls = {
        [DEVICES.SOV]: SOV,
        [DEVICES.CTV]: CTV,
        [DEVICES.HELICOPTER]: Helicopter
    }[type];
    if(cls) {
        const l = new cls();
        l.init(data);
        return l;
    } else throw RangeError("No device type: " + type);
}