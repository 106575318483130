import AuthManagerBase from "coremarine-frontend-lib/apitools/AuthManagerBase";
import ProviderJson from "coremarine-frontend-lib/apitools/ProviderJson";
import CookieManager from 'coremarine-frontend-lib/apitools/CookieManager';


export default class AuthManager extends AuthManagerBase {
    constructor (url, autoRefresh=15) {
        super();
        this._token = "";
        this._autoRefresh = autoRefresh;
        this.apiUrl = url;
        this._provider = new ProviderJson(url);
        this._cookieManager = new CookieManager();
    }

    _loginRequest(userpass) {
        return this._provider.fetchJson('login', {
            method: "POST",
            body: JSON.stringify(userpass)
        });
    }
    _testRequest(token) {
        this._token = token;
        return this._provider.fetchJson("login", this.authOpts());
    }
    authOpts(opts={}) {
        return {
            ...opts,
            headers: {
                Authorization: `Bearer ` + this._token,
                Accept: "application/json",
                ...(opts.headers||{})
            },
        };
    }

    login(credentials) {
        return new Promise((resolve, reject) => {
            this._loginRequest(credentials)
                .then(res => {
                    if(res.token) {
                        this._cookieManager.write({
                            bearer: res.token
                        });
                        this._token = res.token;

                        this.auth(this._token)
                            .then(resolve);
                    }
                    else {
                        reject("Auth is failed");
                    }
                })
                .catch(reject);
        });
    }

    auth(_token) {
        return new Promise((resolve, reject) => {
            const token = _token || this._cookieManager.get('bearer');
            if (!token) {
                reject("No test token");
                return;
            }
            this._testRequest(token)
                .then(resolve)
                .catch(reject);
        });
    }
    _storeToken(token) {
        this._cookieManager.write({
            bearer: token
        });
        this._token = token;
    }
    logout() {
        this._cookieManager.clear();
        this._token = "";
    }
}