import user from "./user";
import Login from "coremarine-frontend-lib/components/Login";

const routes = [
    ...user("/"),
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: ["Login"]
        }
    }
];


export default routes;
