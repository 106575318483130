export default (rootPath = "") => [
    {
        path:  rootPath,
        component: () => import("../views/IndexWrapper"),
        meta: {
            title: ["FRANK"]
        },
        children: [{
            path: "",
            component: () => import("../components/MainMenu/Wrapper"),
            children: [{
                path: "",
                name: "frank_new_project",
                meta: {
                    title: ["New project"]
                },
                component: () => import("../components/MainMenu/NewProject"),
            },{
                path: "to-edit-project",
                name: "frank_edit_project",
                meta: {
                    title: ["Edit project"]
                },
                component: () => import("../components/MainMenu/EditProjectWrapper"),
            },{
                path: "to-view-results",
                name: "frank_view_results",
                meta: {
                    title: ["View results"]
                },
                component: () => import("../components/MainMenu/ViewResultstWrapper"),
                children: [{
                    props: true,
                    name: "frank_results",
                    path: "results/:id/:case_id?",
                    component: () => import("../components/ProjectResults"),
                    meta: {
                        object: "project_results",
                        title: ["Project results"]
                    }
                }]
            },{
                path: "to-compare-results",
                name: "frank_compare_results",
                meta: {
                    title: ["Compare results"]
                },
                component: () => import("../components/MainMenu/CompareResults"),
            },{
                path: "object-library/:objectType?/:objectSubtype?",
                name: "frank_object_library",
                meta: {
                    title: ["Object library"]
                },
                component: () => import("../components/MainMenu/ObjectLibraryWrapper"),
            }]
        }, {
            props: true,
            path: rootPath + "project/:id",
            meta: {
                object: "project",
                title: ["Edit project"]
            },
            component: () => import("../components/ProjectEditor/Wrapper"),
            children: [{
                props: true,
                path: "general/:popup?/:case_id?",
                name: "frank_project_general",
                meta: {
                    title: ["Edit project"]
                },
                component: () => import("../components/ProjectEditor/EditForm/General")
            }, {
                props: true,
                path: "lease_areas/:popup?/:case_id?",
                name: "frank_project_lease_areas",
                meta: {
                    title: ["Edit project"]
                },
                component: () => import("../components/ProjectEditor/EditForm/LeaseArea")
            }, {
                props: true,
                path: "assets/:popup?/:case_id?",
                name: "frank_project_assets",
                meta: {
                    title: ["Edit project"]
                },
                component: () => import("../components/ProjectEditor/EditForm/Assets")
            }, {
                props: true,
                path: "cables/:popup?/:case_id?",
                name: "frank_project_cables",
                meta: {
                    title: ["Edit project"]
                },
                component: () => import("../components/ProjectEditor/EditForm/Cables")
            }, {
                props: true,
                path: "farm_params/:popup?/:case_id?",
                name: "frank_project_farm_params",
                meta: {
                    title: ["Edit project"]
                },
                component: () => import("../components/ProjectEditor/EditForm/AdvancedFarmParams")
            }, {
                props: true,
                path: "mobilization_ports/:popup?/:case_id?",
                name: "frank_project_mobilization_ports",
                meta: {
                    title: ["Edit project"]
                },
                component: () => import("../components/ProjectEditor/EditForm/MobilizationPorts")
            }, {
                props: true,
                path: "maintenance_devices/:popup?/:case_id?",
                name: "frank_project_maintenance_devices",
                meta: {
                    title: ["Edit project"]
                },
                component: () => import("../components/ProjectEditor/EditForm/MaintenanceDevices")
            }, {
                props: true,
                path: "reliability/:popup?/:case_id?",
                name: "frank_project_reliability",
                meta: {
                    title: ["Edit project"]
                },
                component: () => import("../components/ProjectEditor/EditForm/Reliability")
            },{
                props: true,
                path: "preventive_maintenance/:popup?/:case_id?",
                name: "frank_project_preventive_maintenance",
                meta: {
                    title: ["Edit project"]
                },
                component: () => import("../components/ProjectEditor/EditForm/PreventiveMaintenance")
            }, {
                props: true,
                path: "simulation_params/:popup?/:case_id?",
                name: "frank_project_simulation_params",
                meta: {
                    title: ["Edit project"]
                },
                component: () => import("../components/ProjectEditor/EditForm/SimulationParams")
            }, {
                props: true,
                path: "start/:popup?/:case_id?",
                name: "frank_project_start",
                meta: {
                    title: ["Edit project"]
                },
                component: () => import("../components/ProjectEditor/EditForm/Start")
            }]
        }, {
            path: "*",
            component: () => import("coremarine-frontend-lib/components/NotFound")
        }]
    },
];