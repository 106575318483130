import generateId from "../../utils/generateId";

export default class BaseObject {
    id = "";
    name = "Object";
    description = "";
    init(data = {}) {
        Object.assign(this, data);
        if(!data.id) {
            this.id = generateId();
            this.pending = true;
        }
        return this;
    }
}
